// src/components/component/support/messenger-interface.tsx
import React, { useState, useRef, useEffect } from 'react';
import Image from 'next/image';
import { motion } from 'framer-motion';

interface Message {
  id: number;
  text: string;
  userId: string;
  userName: string;
  sender: 'user' | 'admin';
  timestamp?: Date;
}

interface MessengerInterfaceProps {
  onBack: () => void;
  onMessageSend: (message: Message) => Promise<boolean>;
}

export function MessengerInterface({
  onBack,
  onMessageSend,
}: MessengerInterfaceProps) {
  const [messages, setMessages] = useState<Message[]>([]);
  const [inputText, setInputText] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [isMobile, setIsMobile] = useState(false);
  const messagesEndRef = useRef<HTMLDivElement>(null);

  // User info
  const userId = 'tristanquinones@yahoo.com';
  const userName = 'tristan';

  useEffect(() => {
    const checkMobile = () => {
      setIsMobile(window.innerWidth < 768);
    };

    checkMobile();
    window.addEventListener('resize', checkMobile);
    return () => window.removeEventListener('resize', checkMobile);
  }, []);

  const handleSendMessage = async () => {
    if (inputText.trim() === '') return;

    setIsLoading(true);
    setError(null);

    try {
      // Create message object
      const newMessage: Message = {
        id: Date.now(),
        text: inputText,
        userId,
        userName,
        sender: 'user',
      };

      // Add to local state for immediate feedback
      setMessages((prev) => [...prev, newMessage]);
      setInputText('');

      // Send to server
      const success = await onMessageSend(newMessage);
      
      if (success) {
        // Wait a moment and refresh messages
        setTimeout(() => {
          fetchMessages();
        }, 1000);
      }
    } catch (err) {
      console.error('Error sending message:', err);
      setError('Failed to send message. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  // Scroll to bottom when messages change
  useEffect(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [messages]);

  // Fetch messages on load and periodically
  useEffect(() => {
    fetchMessages();
    const intervalId = setInterval(fetchMessages, 10000);
    return () => clearInterval(intervalId);
  }, []);

  const fetchMessages = async () => {
    try {
      setError(null);
      
      // Use XMLHttpRequest for fetching too
      const xhr = new XMLHttpRequest();
      xhr.open('GET', `/api/support/messages/${userId}`, true);
      
      xhr.onload = function() {
        if (xhr.status >= 200 && xhr.status < 300) {
          try {
            const response = JSON.parse(xhr.responseText);
            
            if (response.success && Array.isArray(response.data)) {
              setMessages(response.data);
            } else {
              console.error('Invalid response format:', response);
              setError('Could not load messages. Invalid response format.');
            }
          } catch (e) {
            console.error('Error parsing response:', e, xhr.responseText);
            setError('Could not parse messages data.');
          }
        } else {
          console.error('Server error:', xhr.status, xhr.responseText);
          setError(`Error loading messages: ${xhr.status}`);
        }
      };
      
      xhr.onerror = function() {
        console.error('Request failed');
        setError('Network error while loading messages.');
      };
      
      xhr.send();
    } catch (err) {
      console.error('Error fetching messages:', err);
      setError('Failed to load messages.');
    }
  };

  return (
    <div className={`flex flex-col ${isMobile ? 'h-[90vh]' : 'h-[510px]'} bg-slate-900`}>
      {/* Header */}
      <div className="bg-slate-800/50 backdrop-blur-sm p-4 flex items-center justify-between border-b border-slate-700/50">
        <button
          onClick={onBack}
          className="text-slate-400 hover:text-blue-400 transition-colors duration-300"
        >
          <ChevronLeftIcon className="h-6 w-6" />
        </button>
        <div className="flex items-center space-x-2">
          <Image
            alt="WATCH INSPECT logo"
            className="h-8 w-8"
            height="40"
            src="/512white.png"
            style={{
              aspectRatio: '40/40',
              objectFit: 'cover',
            }}
            width="40"
          />
          <span className="text-slate-200 font-semibold">WATCH INSPECT</span>
        </div>
        <div className="relative">
          <span className="absolute -top-0 -right-0 h-3 w-3 rounded-full bg-green-500 animate-pulse" />
          <Image
            alt="Profile Picture"
            className="h-10 w-10 rounded-full ring-2 ring-slate-700/50 transition-all duration-300 hover:ring-blue-500/50"
            height="50"
            src="/tristan_HEADSHOT.jpg"
            style={{
              aspectRatio: '50/50',
              objectFit: 'cover',
            }}
            width="50"
          />
        </div>
      </div>

      {/* Messages area */}
      <div className="flex-1 overflow-y-auto p-4 space-y-4">
        {error && (
          <div className="bg-red-500/20 border border-red-500/50 text-red-200 p-3 rounded-lg text-center">
            {error}
          </div>
        )}
        
        {messages.length === 0 ? (
          <div className="text-center text-slate-400 mt-8">
            No messages yet. Start a conversation!
          </div>
        ) : (
          messages.map((message, index) => (
            <motion.div
              key={`${message.id}-${index}`}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.3 }}
            >
              <div
                className={`flex items-center ${message.sender === 'user' ? 'justify-end' : 'justify-start'}`}
              >
                <div
                  className={`${
                    message.sender === 'user'
                      ? 'bg-blue-600 text-white'
                      : 'bg-slate-800/50 backdrop-blur-sm text-slate-200'
                  } rounded-xl p-3 max-w-[80%] shadow-lg ${
                    message.sender === 'user'
                      ? 'hover:bg-blue-500'
                      : 'hover:bg-slate-700/50'
                  } transition-all duration-300`}
                >
                  {message.text}
                </div>
              </div>
            </motion.div>
          ))
        )}
        <div ref={messagesEndRef} />
      </div>

      {/* Input area */}
      <div className="p-4 bg-slate-800/50 backdrop-blur-sm border-t border-slate-700/50">
        <div className="flex items-center space-x-2">
          <input
            className="flex-1 p-3 bg-slate-700/30 text-slate-200 placeholder-slate-400 rounded-xl border border-slate-600/50 focus:outline-none focus:ring-2 focus:ring-blue-500/50 transition-all duration-300"
            placeholder="Type your message..."
            type="text"
            value={inputText}
            onChange={(e) => setInputText(e.target.value)}
            onKeyPress={(e) => {
              if (e.key === 'Enter' && !isLoading) {
                handleSendMessage();
              }
            }}
            disabled={isLoading}
          />
          <button
            className={`bg-gradient-to-r from-blue-600 to-blue-700 hover:from-blue-500 hover:to-blue-600 text-white px-6 py-3 rounded-xl shadow-lg transition-all duration-300 hover:shadow-blue-500/25 ${
              isLoading ? 'opacity-50 cursor-not-allowed' : 'hover:scale-[1.02]'
            }`}
            onClick={handleSendMessage}
            disabled={isLoading}
          >
            {isLoading ? 'Sending...' : 'Send'}
          </button>
        </div>
      </div>
    </div>
  );
}

function ChevronLeftIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="m15 18-6-6 6-6" />
    </svg>
  );
}